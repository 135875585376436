$('a[data-toggle="collapse"]').click(function(event) {
	event.preventDefault();
});

$('.search-brand input[name="desconto"]').on('change',function(event) {
	event.preventDefault();
	
	$(this).parents('.radio').siblings('.radio').removeClass('active');
	$(this).parents('.radio').addClass('active');
});

$('[data-toggle="side-menu"]').click(function(event) {
	$($(this).data('target')).toggleClass('open');
});

$('[data-evnt="swipe"]').on('swiperight', function(event) {
	event.preventDefault();
	
	$($(this).data('target')).toggleClass($(this).data('class'));
});