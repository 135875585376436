var LazyImage = (function(){
	'use restrict';
	var isLazed = function(){
		$('lazyimage').each(function(index, el) {
			if(el.getBoundingClientRect().top < window.innerHeight + 200){
				if(!$(this).hasClass('loaded')){
					var classe 	= $(this).data('class') ? 'class="'+$(this).data('class')+'"' : '';
					var img 	= document.createElement('img');
					//*------------------------------------------------------------------------------------------
					img.src 		= $(this).attr('data-src');
					img.alt 		= $(this).attr('data-alt') ? $(this).attr('data-alt') : 'placeholder';
					img.className 	= $(this).attr('data-class') ? $(this).attr('data-class') : 'img-responsive';
					//*------------------------------------------------------------------------------------------
					el.appendChild(img);
					//*------------------------------------------------------------------------------------------
					$(this).addClass('loaded');
				}
			}
		});
	};
	var jaLazyimage = false;

	setTimeout(isLazed(),200);

	$(window).scroll(function(event) {
		if(jaLazyimage) return;

		setTimeout(function(){
			jaLazyimage = false;
		},100);

		isLazed();
	});
})();