$('.btn-toggle-layout').click(function(event) {
	var alvo = $($(this).data('target'));
	var fn = $(this).data('fn');
	var changeLayout = function(){
		if(fn == 'blocos'){
			alvo.removeClass('horizontal');
		}else{
			alvo.addClass('horizontal');
		}
	};

	$('.btn-toggle-layout').removeClass('active');
	$(this).addClass('active');

	alvo.fadeOut('fast', function() {
		changeLayout();
	}).fadeIn('fast');

});

$('[data-toggle="popover"]').click(function(event) {
	$(this).popover('toggle')
});