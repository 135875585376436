function startOwlCarousel (alvo,options){
	if($(alvo).find('> *').length > 1){
		$(alvo).owlCarousel(options);
	}else{
		$(alvo).css('display', 'block');
	}
};

startOwlCarousel('#banner',{
	loop: true,
	autoplay: true,
	items: 1
});

startOwlCarousel('#banner2',{
	loop: true,
	autoplay: true,
	items: 1
});

startOwlCarousel('#produtosEmDestaque',{
	loop: true,
	autoplay: true,
	responsive: {
		0 : {
			items: 1
		},
		600 : {
			items : 2
		},
		992 : {
			items : 4
		}
	},
	nav: true,
	navText: [
		'<i class="glyphicon glyphicon-chevron-left"></i>',
		'<i class="glyphicon glyphicon-chevron-right"></i>',
	]
});

startOwlCarousel('#novosProdutos,#vejaTambem,#maisVendidos',{
	loop: true,
	autoplay: true,
	responsive: {
		0 : {
			items: 1
		},
		600 : {
			items : 2
		},
		992 : {
			items : 4
		}
	},
	nav: true,
	navText: [
		'<i class="glyphicon glyphicon-chevron-left"></i>',
		'<i class="glyphicon glyphicon-chevron-right"></i>',
	]
});

startOwlCarousel('#marcas',{
	loop: true,
	autoplay: true,
	responsive: {
		0 : {
			items: 1
		},
		600 : {
			items : 3
		},
		992 : {
			items : 6
		}
	},
	nav: true,
	navText: [
		'<i class="glyphicon glyphicon-chevron-left"></i>',
		'<i class="glyphicon glyphicon-chevron-right"></i>',
	]
});

startOwlCarousel('#galeriaDeFotos',{
	loop: true,
	autoplay: true,
	responsive: {
		0 : {
			items: 1
		},
		600 : {
			items : 2
		},
		992 : {
			items : 4
		}
	},
	nav: true,
	navText: [
		'<i class="glyphicon glyphicon-chevron-left"></i>',
		'<i class="glyphicon glyphicon-chevron-right"></i>',
	]
});