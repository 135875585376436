$('[data-fn]').click(function(event) {
	var alvo = $(this).data('target');
	var fn = $(this).data('fn');
	var val = parseInt($(alvo).val());

	if(fn == 'minus'){
		if(val > 1){
			$(alvo).val(val-1)
		}
	}else{
		$(alvo).val(val+1)
	}
});