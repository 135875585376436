$('[data-toggle="pagamento"]').click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href');

	$('.tab-pagamento .forma').removeClass('active');
	$(alvo).addClass('active');

	$('.tab-pagamento .tabs li').removeClass('active');
	$(this).parents('li').addClass('active');
});